<template>
  <div class="information">
    <el-card
      class="box-card"
      :style="{ left: 0, minHeight: height + 'px' }"
      v-loading="!userInfo.Id"
    >
      <div slot="header" class="clearfix">
        <h3 style="margin: 0; font-size: 20px">账户信息</h3>
      </div>
      <div class="divFont">
        <el-image
          style="width: 100px; height: 100px"
          :src="userInfo.UserAvatarURL"
          :fit="'cover'"
          @error="imgerror"
        >
          <div slot="error" class="image-slot">
            <el-image
              style="width: 100px; height: 100px"
              :src="imgurl"
              :fit="'cover'"
            ></el-image>
          </div>
        </el-image>
        <p class="sizi">
          <span>账户名称:</span>
          {{ userInfo.UserName }}
        </p>
        <p class="sizi">
          <span>姓名:</span>
          {{ userInfo.RealName }}
        </p>
        <p class="sizi">
          <span>性别:</span>
          {{ userInfo.Sex == 0 ? "女" : "男" }}
        </p>
        <p class="sizi">
          <span>出生日期:</span>
          {{ userInfo.Birthday ? userInfo.Birthday.substring(0, 10) : "" }}
        </p>
        <p class="sizi">
          <span>手机号:</span>
          {{ userInfo.UserPhone }}
        </p>
        <p>
          <span>主子账户:</span>
          {{ userInfo.IsSubAccount ? "子账户" : "主账户" }}
        </p>
        <p v-if="!userInfo.IsSubAccount">
          <span>账户类型:</span>
          {{ userInfo.AccountType }}
        </p>
        <!-- <p class="sizi" v-if="!userInfo.IsSubAccount">
          <span>账户剩余数量:</span>
          {{ userInfo.RemainingQuantity }} 条
        </p> -->
        <p class="sizi" v-if="!userInfo.IsSubAccount">
          <span>到期日期:</span>
          {{ userInfo.EndTime ? userInfo.EndTime.substring(0, 10) : "" }}
        </p>

        <el-button type="text" @click="resetPassword">重置密码</el-button>
        <el-button type="text" @click="resetPhone">更换手机</el-button>
        <el-button type="text" @click="updateInfo">完善信息</el-button>
      </div>
    </el-card>
    <el-card class="box-card" :style="{ right: 0, minHeight: height + 'px' }">
      <div slot="header" class="clearfix">
        <h3 style="margin: 0; font-size: 20px">
          公司信息
          <el-button
            style="float: right; padding: 3px 0; font-size: 20px"
            type="text"
            @click="AddCompanyVisible"
            v-if="
              ((userInfo.AccountType == '免费版' && CompanyList.length < 1) ||
                (userInfo.AccountType == '普惠版' && CompanyList.length < 1) ||
                (userInfo.AccountType == '基础版' && CompanyList.length < 1) ||
                (userInfo.AccountType == '专业版' && CompanyList.length < 1) ||
                userInfo.AccountType == '企业版') &&
              !userInfo.IsSubAccount
            "
            >添加</el-button
          >
        </h3>
      </div>
      <el-table
        :data="CompanyList"
        style="width: 100%; font-size: 19px"
        :max-height="600"
        v-loading="CompanyLoading"
        empty-text="请设置公司信息"
      >
        <el-table-column prop="Title" label="公司名称"></el-table-column>
        <el-table-column label width="160" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="SetTheReminder(scope.row.Id)"
              >设置催签</el-button
            >
            <el-button
              type="text"
              v-if="!userInfo.IsSubAccount"
              @click="handleedit(scope.row.Id)"
              >编辑</el-button
            >

            <el-popconfirm
              confirm-button-text="删除"
              cancel-button-text="取消"
              confirm-button-type="danger"
              icon="el-icon-info"
              icon-color="red"
              title="确认删除公司吗?"
              @confirm="handleDelete(scope.row.Id)"
              style="margin-left: 5px"
            >
              <el-button
                slot="reference"
                type="text"
                v-if="!userInfo.IsSubAccount"
                >删除</el-button
              >
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 添加公司 -->
    <el-drawer
      title="添加公司"
      :visible.sync="CompanyVisible"
      size="80%"
      destroy-on-close
      :wrapperClosable="false"
    >
      <el-form
        ref="CompanyForm"
        :model="form2"
        :rules="rules2"
        label-width="80px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="公司简称" prop="Title">
              <el-input
                v-model="form2.Title"
                placeholder="公司简称"
                autocomplete="off"
                type="text"
                show-word-limit
                :maxlength="8"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="公司全称" prop="FullName">
              <el-input
                v-model="form2.FullName"
                placeholder="公司全称"
                autocomplete="off"
                type="text"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系电话" prop="Phone">
              <el-input
                v-model="form2.Phone"
                placeholder="联系电话"
                autocomplete="off"
                type="text"
                @input="phoneinput"
                show-word-limit
                :maxlength="11"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="省/直辖市">
              <el-select
                style="width: 100%"
                v-model="form2.Province"
                placeholder="请选择省/直辖市"
                @change="changeProvince"
              >
                <el-option
                  v-for="item in ProvinceList"
                  :key="item.Id"
                  :value="item.Id"
                  :label="item.Name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="市">
              <el-select
                style="width: 100%"
                v-model="form2.City"
                placeholder="请选择市"
                @change="changeCity"
              >
                <el-option
                  v-for="item in CityList"
                  :key="item.Id"
                  :value="item.Id"
                  :label="item.Name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="区">
              <el-select
                style="width: 100%"
                v-model="form2.Area"
                placeholder="请选择区"
              >
                <el-option
                  v-for="item in CountyList"
                  :key="item.Id"
                  :value="item.Id"
                  :label="item.Name"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16">
            <el-form-item label="详细地址" prop="Address">
              <el-input
                v-model="form2.Address"
                placeholder="详细地址"
                autocomplete="off"
                type="text"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="分享标题">
              <el-input
                v-model="form2.ReceiptTitle"
                placeholder="请输入分享标题"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="帐期一" prop="Address">
              <el-input
                v-model="form2.AccountingperiodDays"
                placeholder="请输入帐期一"
                autocomplete="off"
                type="text"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="帐期二">
              <el-input
                v-model="form2.Accountingperiod2Days"
                placeholder="请输入帐期二"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="帐期三">
              <el-input
                v-model="form2.Accountingperiod3Days"
                placeholder="请输入帐期三"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="签收验证">
              <el-radio-group v-model="form2.IscheckPhone">
                <el-radio-button :label="true">是</el-radio-button>
                <el-radio-button :label="false">否</el-radio-button>
              </el-radio-group>
              <el-popover
                placement="top-start"
                title="签收验证"
                width="200"
                trigger="hover"
                content="签收验证开启后只允许当前客户下已有签收人进行签收。"
              >
                <i slot="reference" class="el-icon-question question"></i>
              </el-popover>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="位置验证">
              <div style="display: flex">
                <el-radio-group style="width: 115px" v-model="form2.IsPosition">
                  <el-radio-button label="1">是</el-radio-button>
                  <el-radio-button label="0">否</el-radio-button>
                </el-radio-group>
                <el-input
                  v-if="form2.IsPosition == '1'"
                  v-model.number="form2.Distance"
                  style="flex: 1"
                  placeholder="验证距离"
                >
                  <span slot="suffix">m</span>
                </el-input>
                <el-popover
                  v-else
                  placement="top-start"
                  title="位置验证"
                  width="200"
                  trigger="hover"
                  content="位置验证开启后只允许在收货地址一定范围内进行签收。"
                >
                  <i slot="reference" class="el-icon-question question"></i>
                </el-popover>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="司法存证">
              <el-radio-group disabled v-model="form2.IsEvidence">
                <el-radio-button :label="true">是</el-radio-button>
                <el-radio-button :label="false">否</el-radio-button>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="16">
            <el-form-item label="验证方式">
              <el-radio-group v-model="form2.SignType" disabled>
                <el-radio-button label="3">微信签收</el-radio-button>
                <el-radio-button label="0">二要素</el-radio-button>
                <el-radio-button label="2">三要素</el-radio-button>
                <el-radio-button label="1">人脸识别</el-radio-button>
              </el-radio-group>
              <el-popover
                placement="top-start"
                title="验证方式"
                width="400"
                trigger="hover"
              >
                <span
                  >微信签收: 签收人在使用微信签收时可直接对回执单进行签收</span
                ><br />
                <span>二要素：签收人在首次签收前需进行姓名及身份证号验证;</span
                ><br />
                <span
                  >三要素：签收人在首次签收前需进行姓名、身份证号及手机号验证;</span
                ><br />
                <span>人脸识别：签收人在首次签收前需人脸识别验证验证;</span
                ><br />
                <i slot="reference" class="el-icon-question question"></i>
              </el-popover>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="启用验证">
              <el-radio-group v-model="form2.SignState">
                <el-radio-button :label="true">启用</el-radio-button>
                <el-radio-button :label="false">关闭</el-radio-button>
              </el-radio-group>
              <el-popover
                placement="top-start"
                title="验证码验证"
                width="200"
                trigger="hover"
                content="签收时需要输入短信验证码才能执行签收。"
              >
                <i slot="reference" class="el-icon-question question"></i>
              </el-popover>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="公司logo" prop="Logo">
              <div style="display: flex">
                <UploaderAvatar v-model="form2.Logo" />
                <span style="margin-left: 10px">此logo上传为公司模板logo</span>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <el-tabs v-if="temShow" v-model="labelName">
        <el-tab-pane label="回执模板" name="first">
          <div class="imageBox">
            <div
              :span="8"
              v-for="item in TmpList"
              :key="item.Id"
              :class="form2.TemId == item.Id ? 'image borderColor' : 'image'"
              @click="changeTmp(item.Id)"
            >
              <el-image style="width: 100%" :src="item.Image"></el-image>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
      <div style="height: 50px"></div>
      <div
        style="
          position: absolute;
          bottom: 0px;
          left: 0;
          background: #fff;
          width: calc(100% - 20px);
          text-align: right;
          padding: 10px 20px;
          box-sizing: border-box;
          z-index: 999;
        "
      >
        <el-button @click="CompanyVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="CompanyConfirm"
          :loading="CompanyLoading"
          >确 定</el-button
        >
      </div>
    </el-drawer>

    <!-- 重置密码框 -->
    <el-dialog title="重置密码" :visible.sync="passwordVisible">
      <el-form
        ref="PasswordForm"
        :model="form"
        label-width="80px"
        :rules="rules"
      >
        <el-form-item label="旧密码" prop="oldPassword">
          <el-input
            v-model="form.oldPassword"
            placeholder="旧密码"
            autocomplete="off"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input
            v-model="form.newPassword"
            placeholder="新密码"
            autocomplete="off"
            type="password"
          ></el-input>
        </el-form-item>
        <el-form-item label="重复密码" prop="resetPassword">
          <el-input
            v-model="form.resetPassword"
            placeholder="重复密码"
            autocomplete="off"
            type="password"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="passwordVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="PasswordConfirm"
          :loading="CompanyLoading"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <!-- 更换手机号 -->

    <el-dialog title="更换手机" :visible.sync="PhoneVisible">
      <el-form
        ref="PhoneForm"
        :model="form1"
        label-width="80px"
        :rules="rules1"
      >
        <el-form-item label="手机号" prop="newPhone">
          <el-input
            v-model="form1.newPhone"
            placeholder="手机号"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="code">
          <div class="formLabel">
            <el-input v-model="form1.code" placeholder="验证码"></el-input>
            <el-button :disabled="disabled" @click="sendCode">
              {{ !disabled ? "发送验证码" : oneMinute + "重新发送" }}
            </el-button>
          </div>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="PhoneVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="PhoneConfirm"
          :loading="CompanyLoading"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <SetTheReminder ref="SetTheReminder" @setOK="getComputedList" />
    <updateInfo ref="updateInfo" :parentObj="{ ...this }" />
  </div>
</template>

<script>
import SetTheReminder from "@/components/SetTheReminder.vue";
import updateInfo from "./updateInfo.vue";
import op from "@/utils/OperatorCache";
import UploaderAvatar from "./UploaderAvatar.vue";
const regExp = new RegExp("^1[3456789]\\d{9}$"); // 手机号正则
export default {
  props: {},
  components: {
    updateInfo,
    UploaderAvatar,
    SetTheReminder,
  },
  mounted() {},
  created() {
    this.getProvinceList();
    this.getTmpList();
    this.getUserInfo();
    console.log(this.height);
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.newPassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      labelName: "first",
      temShow: true,
      // 公司
      CompanyVisible: false,
      form2: {},
      rules2: {
        Title: [{ required: true, message: "请输入公司名称", trigger: "blur" }],
        Phone: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("请输入手机号"));
              } else if (!regExp.test(value)) {
                callback(new Error("请输入正确的手机号"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        TemId: [{ required: true, message: "请选择回执模板", trigger: "blur" }],
      },
      // 密码
      passwordVisible: false,
      form: {},
      rules: {
        oldPassword: [
          { required: true, message: "请输入旧密码", trigger: "blur" },
        ],
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
        ],
        resetPassword: [
          { required: true, validator: validatePass, trigger: "blur" },
        ],
      },
      // 手机
      PhoneVisible: false,
      form1: {},
      rules1: {
        newPhone: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error("请输入手机号"));
              } else if (!regExp.test(value)) {
                callback(new Error("请输入正确的手机号"));
              } else {
                callback();
              }
            },
            trigger: "blur",
          },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
      timer: null,
      oneMinute: 60,
      disabled: false,
      userInfo: {},
      CompanyList: [],
      CompanyLoading: false,
      ProvinceList: [],
      TmpList: [],
      CityList: [],
      CountyList: [],
      imgurl: require("@/assets/logo.png"),
    };
  },
  methods: {
    // 设置催签周期
    SetTheReminder(Id) {
      this.$refs.SetTheReminder.open(Id);
    },
    phoneinput(value) {
      this.form2.Phone = /^[0-9]*$/.test(parseInt(value))
        ? String(parseInt(value)).replace(".", "")
        : "";
    },
    imgerror(e, err) {
      console.log(e.src);
      console.log(e, err);
    },
    handleedit(Id) {
      this.temShow = false;
      this.$http
        .post("/Base_Manage/Base_Company/GetTheData?id=" + Id, {})
        .then((res) => {
          if (res.Success) {
            this.CompanyVisible = true;

            this.form2 = {};
            setTimeout(() => {
              this.form2 = res.Data;
            }, 1);
            this.getCityList(res.Data.Province);
            this.getCountyList(res.Data.City);
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    changeProvince(e) {
      this.$set(this.form2, "cCity", "");
      this.$set(this.form2, "cCounty", "");
      this.getCityList(e);
    },
    changeCity(e) {
      this.$set(this.form2, "cCounty", "");
      this.getCountyList(e);
    },
    changeTmp(Id) {
      this.$set(this.form2, "TemId", Id);
    },
    getProvinceList() {
      this.$http
        .post("/Base_BasicData/Base_District/GetDataListByPId?pId=0", {})
        .then((resJson) => {
          this.ProvinceList = resJson.Data;
        });
    },
    // 市列表
    getCityList(id) {
      this.$http
        .post("/Base_BasicData/Base_District/GetDataListByPId?pId=" + id, {})
        .then((resJson) => {
          this.CityList = resJson.Data;
        });
    },
    // 区列表
    getCountyList(id) {
      this.$http
        .post("/Base_BasicData/Base_District/GetDataListByPId?pId=" + id, {})
        .then((resJson) => {
          this.CountyList = resJson.Data;
        });
    },
    getTmpList() {
      this.$http
        .post("/Base_Manage/Base_Company/InitializationTmp")
        .then((resJson) => {
          this.TmpList = resJson.Data;
        });
    },
    // 用户信息
    getUserInfo() {
      // if (sessionStorage.getItem("userInfo")) {
      //   this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
      //   this.getComputedList();
      //   return;
      // }
      setTimeout(() => {
        console.log(op.info);
        op.updateInfo(() => {
          this.userInfo = op.info;
        });

        this.getComputedList();
      }, 1000);
    },
    // 更新信息
    updateInfo() {
      this.$refs.updateInfo.openForm(this.userInfo.Id);
    },
    // 公司
    getComputedList() {
      this.CompanyLoading = true;
      this.$http
        .post("/Base_Manage/Base_Company/GetDataCompanyData", {
          Search: {
            condition: "CreatorId",
            keyword: this.userInfo.Id,
          },
        })
        .then((res) => {
          this.CompanyLoading = false;
          if (res.Success) {
            this.CompanyList = res.Data;
          }
        });
    },
    AddCompanyVisible() {
      this.clearValidateForms("CompanyForm");
      this.CompanyVisible = true;
      this.temShow = true;
      this.form2 = {
        SignType: "3", // 人脸验证
        IscheckPhone: false, // 签收验证
        IsEvidence: false, // 存证存储
        IsPosition: "0", // 位置验证
        ReceiptTitle: "电子回执", // 微信分享标题
        SignState: false, // 启用验证码
      };
    },
    CompanyConfirm() {
      this.$refs["CompanyForm"].validate((valid) => {
        if (!valid) {
          return;
        }
        if (!this.form2.TemId) return this.$message.error("请选择回执模板");

        this.CompanyLoading = true;
        this.$http
          .post("/Base_Manage/Base_Company/SaveData", this.form2)
          .then((res) => {
            this.CompanyLoading = false;
            if (res.Success) {
              this.$message.success("操作成功");
              this.CompanyVisible = false;

              this.getComputedList();
            } else {
              this.$message.error(res.Msg);
            }
          });
      });
    },
    handleDelete(id) {
      this.$http
        .post("/Base_Manage/Base_Company/DeleteData", [id])
        .then((res) => {
          if (res.Success) {
            this.getComputedList();
            this.$message.success("操作成功");
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    // 密码
    resetPassword() {
      this.clearValidateForms("PasswordForm");
      this.passwordVisible = true;
      this.form = {};
    },
    PasswordConfirm() {
      console.log(123);
      this.$refs["PasswordForm"].validate((valid) => {
        if (!valid) {
          return;
        } else {
          this.CompanyLoading = true;
          this.$http
            .post("/Base_Manage/Home/ChangePwd", {
              oldPwd: this.form.oldPassword,
              newPwd: this.form.newPassword,
            })
            .then((res) => {
              this.CompanyLoading = false;
              if (res.Success) {
                this.$message.success("密码修改成功,请重新登录");
                sessionStorage.removeItem("token");
                sessionStorage.removeItem("userInfo");
                this.$router.replace("/Login");
              } else {
                this.$message.error(res.Msg);
              }
            });
        }
      });
    },
    // 手机
    resetPhone() {
      this.clearValidateForms("PhoneForm");
      this.PhoneVisible = true;
      this.form1 = {};
    },
    PhoneConfirm() {
      this.$refs["PhoneForm"].validate((valid) => {
        console.log(valid);
        if (!valid) {
          return;
        }
        this.CompanyLoading = true;
        this.$http
          .post(
            "/Base_Manage/Base_User/ConfirmPhone?UserPhone=" +
              this.userInfo.UserPhone +
              "&Phone=" +
              this.form1.newPhone +
              "&SmsCode=" +
              this.form1.code
          )
          .then((res) => {
            this.CompanyLoading = false;
            if (res.Success) {
              this.$message.success("手机号修改成功,请重新登录");
              sessionStorage.removeItem("token");
              op.clear();
              window.open("http://demo.yoojet.com/Login?tap=1", "_self");
            } else {
              this.$message.error(res.Msg);
            }
          });
      });
    },
    // 验证码发送
    sendCode() {
      this.$refs["PhoneForm"].validateField("newPhone", (err) => {
        if (err) {
          return;
        }
        clearInterval(this.timer);
        this.disabled = true;
        this.timer = setInterval(() => {
          this.oneMinute--;
          if (this.oneMinute <= 0) {
            clearInterval(this.timer);
            this.oneMinute = 60;
            this.disabled = false;
          }
        }, 1000);
        this.$http
          .post(
            "/Base_Manage/Base_User/ReplacePhone?UserPhone=" +
              this.userInfo.UserPhone +
              "&Phone=" +
              this.form1.newPhone
          )
          .then((res) => {
            if (res.Success) {
              this.$message.success("验证码已成功发送");
            } else {
              this.$message.error(res.Msg);
            }
          });
      });
    },
    // 表单刷新校验
    clearValidateForms(form) {
      if (this.$refs[form]) {
        this.$refs[form].clearValidate();
      }
    },
  },
  computed: {
    height() {
      return document.documentElement.clientHeight * 0.75;
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.question {
  margin-left: 5px;
  font-size: 24px;
  vertical-align: middle;
  color: #ccc;
  cursor: pointer;
}
/deep/.el-drawer__header {
  margin-bottom: 20px;
}
/deep/.el-drawer__body {
  padding: 0 10px;
}
.imageBox {
  display: flex;
  flex-wrap: wrap;
  .image {
    width: 32%;
    box-sizing: border-box;
    border: 1px solid #000;
    margin-right: 1%;
    margin-bottom: 10px;
    cursor: pointer;
  }
  .borderColor {
    border: 2px solid #409eff;
  }
}
.information {
  position: relative;
  .box-card {
    position: absolute;
    top: 0;
    width: 48.5%;
    height: 600px;
  }
  .formLabel {
    display: flex;
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.divFont {
  font-size: 19px;
}
</style>
